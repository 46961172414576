import { SyntheticEvent, useEffect, useState } from 'react';
import {
  faCalendarCheck,
  faChevronDown,
  faMapLocationDot,
  faMagnifyingGlass,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { getFacetCode } from '@lamesarv-sdk/tools';
import { searchEvents } from '@/tools/events';

import { useGetEvents, useGetVenues } from '@/hooks/events';
import { Button, ButtonType, GenericHtml, Spinner } from '@lamesarv-sdk/components';

import { IComponentBase, PropsWithClasses } from '@lamesarv-sdk/types';
import { IEvent } from '@/types/cms';

export type IEventFilter = { label: string; value: string };

export interface IEventSearchProps {
  callbackSearch: (query: string) => void;
  placeholder?: string;
}

export const EventSearch = (props: IEventSearchProps) => {
  const handleSearch = async (e: SyntheticEvent) => {
    e.preventDefault();
    const value = ((e.target as HTMLFormElement).query as HTMLInputElement).value;

    props.callbackSearch(value);
  };

  return (
    <form className="w-full flex flex-row" onSubmit={handleSearch}>
      <input
        type="search"
        name="query"
        placeholder={props.placeholder ?? 'Search'}
        className="w-full py-2 px-4 border border-gray-200 border-r-0 rounded-sm focus:ring-gray-400"
      />
      <Button
        type={ButtonType.flat}
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="w-4 h-4 text-white" />}
        htmlType="submit"
        title="Search"
        className="bg-neutral-900 text-white py-2 px-4 rounded-l-none rounded-r-sm hover:bg-neutral-800 focus:outline-none"
      />
    </form>
  );
};

export const EventItem = (props: IEvent) => {
  const startDate = new Date(props.startDate);

  return (
    <a
      href={`/events/${props.slug}`}
      className={twMerge(
        'grid grid-cols-1 gap-8 items-center rounded-sm drop-shadow-sm p-6 border border-gray-200 hover:shadow-md md:grid-cols-4',
        props.isExpired && 'bg-gray-50 grayscale',
      )}
    >
      <div className={twMerge('col-span-1', props.isExpired && 'opacity-35')}>
        <div className="flex flex-row gap-2 items-center">
          <FontAwesomeIcon icon={faCalendarCheck} className="w-10 h-10 text-gray-300" />
          <div className="flex flex-col">
            <div className="text-lg font-bold">
              {startDate.toLocaleDateString('en-US', { weekday: 'long' })}{' '}
              {startDate.toLocaleDateString('en-US', { day: '2-digit' })}
            </div>
            <div className="text-gray-600">
              {startDate.toLocaleDateString('en-US', { month: 'long' })}{' '}
              {startDate.toLocaleDateString('en-US', { year: 'numeric' })}
            </div>
          </div>
        </div>
      </div>
      <div className={twMerge('col-span-2', props.isExpired && 'opacity-35')}>
        <h2 className="text-xl font-bold mb-2">
          {props.isExpired && <span className="text-black">{props.title}</span>}
          {!props.isExpired && <span className="text-black">{props.title}</span>}
        </h2>
        <div className="flex flex-row gap-2 items-center mb-2">
          <FontAwesomeIcon icon={faMapLocationDot} className="w-10 h-10 text-gray-300 group-hover:text-black" />
          <div className="flex flex-col">
            <p className="text-sm text-gray-600">
              {props.venue.city}, {props.venue.state}
            </p>
            <p className="text-sm font-bold">{props.venue.title}</p>
          </div>
        </div>
        <GenericHtml content={props.venue.features} />
        {!props.isExpired && (
          <div className="flex flex-row gap-1 text-black mt-2">
            <span className="uppercase text-black font-bold">Cost</span>
            <span className="">{props.cost ? `${props.cost.toLocaleString()} USD` : 'Free'}</span>
          </div>
        )}
      </div>
      <div className={twMerge('col-span-1', props.isExpired && 'opacity-35')}>
        <img src={props.image} alt="Event Image" className="w-full object-cover rounded-sm" />
      </div>
    </a>
  );
};

export interface IEventFilterProps extends PropsWithClasses {
  title: string;
  items: IEventFilter[];
  itemsCurrent: string[];
  callbackSelect: (values: string[]) => void;
}

export const EventFilter = (props: IEventFilterProps) => {
  const handleSelect = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    if (props.itemsCurrent.includes(value)) {
      props.callbackSelect(props.itemsCurrent.filter((item) => item !== value));
    } else {
      props.callbackSelect([...props.itemsCurrent, value]);
    }
  };

  return (
    <Popover className={twMerge('relative border py-2.5 px-3 bg-white', props.className)}>
      <PopoverButton
        className={twMerge(
          'w-full flex flex-row gap-1 items-center justify-between text-sm font-medium focus:outline-none',
          props.classInput,
        )}
      >
        <span className={props.classTitle}>{props.title}</span>
        <FontAwesomeIcon icon={faChevronDown} className={twMerge('w-4 h-4 text-gray-300', props.classIcon)} />
        {props.itemsCurrent.length > 0 && (
          <div className="absolute -top-2 -right-2 leading-none text-xs text-white bg-red-600 py-1 px-2 rounded-full">
            {props.itemsCurrent.length}
          </div>
        )}
      </PopoverButton>
      <PopoverPanel className="absolute w-full right-0 z-10 mt-4 origin-top-right rounded-md bg-white p-4 shadow-2xl transition md:w-auto focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="flex flex-col gap-2">
          {!props.items.length && (
            <span className="text-sm font-medium text-gray-300 whitespace-nowrap">No items available</span>
          )}
          {props.items.map((item, index) => (
            <label key={index} className="flex flex-row gap-2 items-center">
              <input
                type="checkbox"
                value={item.value}
                checked={props.itemsCurrent.includes(item.value)}
                onChange={handleSelect}
                className="h-8 w-8 rounded border-gray-300 text-indigo-600 md:h-4 md:w-4 focus:ring-indigo-500"
              />
              <span className="text-sm font-medium text-gray-900 capitalize whitespace-nowrap">{item.label}</span>
            </label>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export interface IEventFilterPillProps {
  prefix: string;
  title: string;
  value: string;
  callbackRemove: (value: string) => void;
}

export const EventFilterPill = (props: IEventFilterPillProps) => {
  return (
    <div className="relative flex flex-row items-center rounded-full border border-gray-300 bg-white">
      <div className="text-xs text-gray-400 bg-gray-200 py-1.5 pl-3 pr-2 rounded-l-full">{props.prefix}</div>
      <div className="text-xs py-1.5 px-2">{props.title}</div>
      <FontAwesomeIcon
        icon={faXmark}
        className="w-4 h-4 pr-3 cursor-pointer"
        onClick={() => props.callbackRemove(props.value)}
      />
    </div>
  );
};

export interface IComponentEventsList extends IComponentBase, PropsWithClasses {
  filterByState?: string;
  filterByCity?: string;
  filterByVenue?: string;
  displaySearch?: boolean;
  displayFilterState?: boolean;
  displayFilterCity?: boolean;
  displayFilterVenue?: boolean;
  classHeader?: string;
}

export const EventList = (props: IComponentEventsList) => {
  const { items: venuesBase } = useGetVenues({});
  const { isLoading, fetchItems: fetchEvents } = useGetEvents({ skipFetch: true });

  const [query, setQuery] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<string[]>(
    props.filterByState ? [getFacetCode(props.filterByState)] : [],
  );
  const [selectedCities, setSelectedCities] = useState<string[]>(
    props.filterByCity ? [getFacetCode(props.filterByCity)] : [],
  );
  const [selectedVenues, setSelectedVenues] = useState<string[]>(
    props.filterByVenue ? [getFacetCode(props.filterByVenue)] : [],
  );

  const [activeEvents, setActiveEvents] = useState<IEvent[]>([]);
  const [expiredEvents, setExpiredEvents] = useState<IEvent[]>([]);

  const states = venuesBase.reduce((acc: IEventFilter[], venue) => {
    if (!acc.find((item) => item.value === getFacetCode(venue.state))) {
      acc.push({
        label: venue.state,
        value: getFacetCode(venue.state),
      });
    }

    return acc;
  }, []);

  const cities = venuesBase.reduce((acc: IEventFilter[], venue) => {
    if (
      selectedStates.includes(getFacetCode(venue.state)) &&
      !acc.find((item) => item.value === getFacetCode(venue.city))
    ) {
      acc.push({
        label: venue.city,
        value: getFacetCode(venue.city),
      });
    }

    return acc;
  }, []);

  const venues = venuesBase.reduce((acc: IEventFilter[], venue) => {
    if (
      (selectedStates.includes(getFacetCode(venue.state)) || selectedCities.includes(getFacetCode(venue.city))) &&
      !acc.find((item) => item.value === getFacetCode(venue.title))
    ) {
      acc.push({
        label: venue.title,
        value: getFacetCode(venue.title),
      });
    }

    return acc;
  }, []);

  const getFilterLabel = (filters: IEventFilter[], value: string) => {
    return filters.find((item) => item.value === value)?.label;
  };

  const getItems = async () => {
    const baseItems = await fetchEvents({
      amount: 1000,
    });

    const parsedItems = searchEvents(baseItems, query, selectedStates, selectedCities, selectedVenues);

    const _activeEvents = parsedItems.filter((item) => !item.isExpired);
    const _expiredEvents = parsedItems.filter((item) => item.isExpired);

    setActiveEvents(_activeEvents);
    setExpiredEvents(_expiredEvents);
  };

  useEffect(() => {
    getItems();
  }, [query, selectedStates, selectedCities, selectedVenues]);

  const displayHeader = props.displaySearch || props.displayFilterState || props.displayFilterCity;
  const displayFiltersList =
    (selectedStates.length > 0 && props.displayFilterState) ||
    (selectedCities.length > 0 && props.displayFilterCity) ||
    (selectedVenues.length > 0 && props.displayFilterVenue);

  return (
    <div className={twMerge('state', props.className)}>
      {displayHeader && (
        <div className={twMerge('bg-neutral-100 border-b border-neutral-200', props.classHeader)}>
          <div className="max-w-7xl space-y-12 mx-auto p-4 md:p-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 justify-between items-center md:flex-row md:gap-4">
                {props.displaySearch && (
                  <EventSearch callbackSearch={(newQuery: string) => setQuery(newQuery)} placeholder="Search Events" />
                )}
                {(props.displayFilterState || props.displayFilterCity || props.displayFilterVenue) && (
                  <PopoverGroup className="w-full flex flex-col gap-2 items-center md:flex-row md:gap-4 md:w-auto">
                    {props.displayFilterState && (
                      <EventFilter
                        title="States"
                        items={states}
                        itemsCurrent={selectedStates}
                        callbackSelect={(newSelectedStates: string[]) => {
                          setSelectedStates(newSelectedStates);
                          setSelectedCities([]);
                          setSelectedVenues([]);
                        }}
                        className="w-full md:w-auto md:min-w-24"
                      />
                    )}
                    {props.displayFilterCity && (
                      <EventFilter
                        title="Cities"
                        items={cities}
                        itemsCurrent={selectedCities}
                        callbackSelect={(newSelectedCities: string[]) => {
                          setSelectedCities(newSelectedCities);
                          setSelectedVenues([]);
                        }}
                        className="w-full md:w-auto md:min-w-24"
                      />
                    )}
                    {props.displayFilterVenue && (
                      <EventFilter
                        title="Venues"
                        items={venues}
                        itemsCurrent={selectedVenues}
                        callbackSelect={(newSelectedVenues: string[]) => setSelectedVenues(newSelectedVenues)}
                        className="w-full md:w-auto md:min-w-24"
                      />
                    )}
                  </PopoverGroup>
                )}
              </div>
              {displayFiltersList && (
                <div className="flex flex-row flex-wrap gap-2 items-center">
                  <span className="text-sm text-gray-500 font-medium">Filters:</span>
                  {props.displayFilterState &&
                    selectedStates.map((state, index) => (
                      <EventFilterPill
                        key={index}
                        prefix="State"
                        title={getFilterLabel(states, state)}
                        value={state}
                        callbackRemove={(value: string) => {
                          setSelectedStates(selectedStates.filter((item) => item !== value));
                          setSelectedCities([]);
                          setSelectedVenues([]);
                        }}
                      />
                    ))}
                  {props.displayFilterCity &&
                    selectedCities.map((city, index) => (
                      <EventFilterPill
                        key={index}
                        prefix="City"
                        title={getFilterLabel(cities, city)}
                        value={city}
                        callbackRemove={(value: string) => {
                          setSelectedCities(selectedCities.filter((item) => item !== value));
                          setSelectedVenues([]);
                        }}
                      />
                    ))}
                  {props.displayFilterVenue &&
                    selectedVenues.map((venue, index) => (
                      <EventFilterPill
                        key={index}
                        prefix="Venue"
                        title={getFilterLabel(venues, venue)}
                        value={venue}
                        callbackRemove={(value: string) =>
                          setSelectedVenues(selectedVenues.filter((item) => item !== value))
                        }
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex flex-row justify-center items-center h-96">
          <Spinner />
        </div>
      )}
      {/* Events */}
      <div className={twMerge('max-w-7xl space-y-12 mx-auto p-4 md:p-8', props.classList)}>
        {isLoading === false && activeEvents.length === 0 && (
          <div className="flex flex-row justify-center items-center">
            <span className="text-gray-300">No events found</span>
          </div>
        )}
        {activeEvents.map((item, index) => (
          <EventItem key={index} {...item} />
        ))}
      </div>
      {/* Past Events */}
      <div className={twMerge('max-w-7xl space-y-12 mx-auto p-4 md:p-8', props.classList)}>
        <div className="relative border-b border-neutral-200 text-center">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 text-xs text-gray-300 uppercase whitespace-nowrap">
            Past events
          </div>
        </div>
        {isLoading === false && expiredEvents.length === 0 && (
          <div className="flex flex-row justify-center items-center">
            <span className="text-gray-300">No past events found</span>
          </div>
        )}
        {expiredEvents.map((item, index) => (
          <EventItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
